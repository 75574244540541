.draggable-calculator {
  position: fixed;
  z-index: 1000;
  background-color: black;
  border-radius: 5px;
}

.desmos-container {
  width: 400px;
  min-height: 610px;
  border: 1px solid black;
}

.calculator-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  color: white;
  font-size: 16px;
  cursor: move;
}

.calculator-header .circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: black;
  cursor: pointer;
}

.calculator-header .faGridHorizontal {
  margin-left: auto;
  margin-right: auto;
}

.close-button-container {
  justify-content: flex-end;
}

.cursor-pointer {
  cursor: pointer !important;
}

.calculator-heading-font {
  font-size: 1.3rem;
}

/* handles the small calculator sizes */
/* For small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .calculator-size {
    width: 250px;
    height: 80vh;
  }
}

/* For medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .calculator-size {
    width: 45vw;
    height: 75vh;
  }
}

/* For large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .calculator-size {
    width: 375px;
    height: 80vh;
  }
}

/* For extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .calculator-size {
    width: 430px;
    height: 80vh;
  }
}

/* Handles the expanded calculator sizes */
.calculator-expanded {
  width: 80vw;
  height: 80vh;
}
@media (min-width: 576px) {
  .calculator-expanded {
    width: 90vw;
    height: 70vh;
  }
}

@media (min-width: 768px) {
  .calculator-expanded {
    width: 90vw;
    height: 70vh;
  }
}

@media (min-width: 992px) {
  .calculator-expanded {
    width: 60vw;
    height: 50vh;
  }
}

@media (min-width: 1200px) {
  .calculator-expanded {
    width: 50vw;
    height: 50vh;
  }
}
