
.main-college-container{
    height: 100vh;
    margin-left: -7px;
}

.college-main-splash{
    height: 92vh;
    width: auto;
    object-fit: contain;
    z-index: 5;
    position: relative;
    overflow: hidden;
}

.college-main-splash::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0);
    opacity: 1
}

.college-main-splash-image{
    z-index: 1;
    height: 92%;
    width: auto;
    transform: rotate(10deg);

}

.college-main-page{
    background-color: black;
    width: 103.2vw;
    min-height: 120%; 
    display: flex !important;
    flex: 1;
    height: fit-content !important;
}

.container-for-div-above-div{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100vw;
}

.rounded-border-card{
    background-color: rgb(255, 255, 255);
    border-radius: 25px;
}

.row-above-image{
    z-index: 9;
}

.college-name-heading{
    font-size: 2.5rem;
    display:inline-block;
    margin:5% auto;
    border-radius: 25px;
    padding:.1em;
    line-height:1.5em;
    background:rgb(255, 254, 254);
    border:solid 1px rgb(7, 87, 0);
    transform: rotate(-5deg);
}

.special-recognition-banner{
    transform: rotate(7.5deg);
    display:inline-block;
    margin:5% auto;
    border-radius: 25px;
    padding:.1em;
    line-height:1.5em;
    background:rgb(255, 254, 254);
    border:solid 1px rgb(7, 87, 0);
}

.ivy-league-font{
    font-family: 'Amatic SC' ;
    font-size: 30px;
    font-weight: bold;
    /* border: 1px solid rgb(0, 79, 0); */
    background-color: rgb(0, 79, 0);
    color: white;
    font-stretch: extra-expanded;
}

.top-school-font{
    color: rgb(86, 0, 86);
    font-size: 20px;
    outline: 5px dotted #6C009A;
    outline-offset: 0px;
}

.college-feel-card{
    transform: rotate(3deg);
    line-height: 2.5rem;
    overflow: scroll;
    font-family: 'Amatic SC' ;
    font-size: 20px;
    min-width: 15rem;
    min-height: 15rem;
}

.college-feel-card h4{
    font-size: 1.0rem;
}
.college-feel-card h6{
    font-size: 1.2rem;
}

.the-feel-more-button{
    font-size: 1.2rem;
    margin-top: -1.5rem;
    margin-bottom: 0;
}

.city-and-state{
    font-size: 1rem;
    line-height: 1rem;
    font-stretch: extra-expanded;
}

.student-profile-summary{
    transform: rotate(-3deg);
    overflow: scroll;
}

.student-profile-summary h4{
    font-size: 1.2rem;
}
.student-profile-summary h6{
    font-size: 1.2rem;
}

.the-vibe-heading{
    max-width: fit-content;
}

.student-profile-card{
    transform: rotate(-6deg);
    overflow: scroll;
    font-family: 'Amatic SC' ;
    font-size: 20px;
    min-width: 15rem;
    min-height: 15rem;
}

.student-profile-card h6{
    font-size: 1.2rem;
}

.sat-profile-card{
    transform: rotate(-7deg);
    max-height: 17rem;
    line-height: 2.5rem;
    overflow: scroll;
    font-family: 'Amatic SC' ;
    font-size: 20px;
}
.sat-profile-card h6{
    font-size: 1.2rem;
}

.act-profile-card{
    transform: rotate(7deg);
    max-height: 17rem;
    line-height: 2.5rem;
    overflow: scroll;
    font-family: 'Amatic SC' ;
    font-size: 20px;
}
.act-profile-card h6{
    font-size: 1.2rem;
}

.test-requirements-card{
    transform: rotate(0deg);
    max-height: 17rem;
    line-height: 2.5rem;
    overflow: scroll;
    font-family: 'Amatic SC' ;
    font-size: 20px;
}
.test-requirements-card h6{
    font-size: 1.2rem;
}

.ranking-category{
    font-size: 1.2rem;
}

.admit-rate-card{
    transform: rotate(0deg);
    max-height: 17rem;
    line-height: 2.5rem;
    overflow: scroll;
    font-family: 'Amatic SC' ;
    font-size: 20px;
}

.bg-color-yellow{
    background-color: yellow;
}
.bg-color-bright-red{
    background-color: #FB0006 !important;
}
.bg-color-dark-green{
    background-color: rgb(0, 79, 0);
}
.test-optional-warning-label{
    width: max-content;
    border: 2px solid black;
    border-radius: 10px;
}
.heart-column{
    position: absolute;
    top: 0;
    right: 0;
}

.start-page-header-container{

}
.student-card-container{
    max-height: fit-content;
}

.student-fact-list{
    min-height: 2rem;
}

.ranking-card{
    margin-top: 5rem;
}

.min-height-15rem{
    min-height: 15rem;
}