.chat-container {
  max-height: 100%;
  min-height: 100%;
  height: 100%;
  min-width: 100%;
  overflow-y: scroll;
  border-radius: 25px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.28);
}

.messages-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0px;
  overflow-y: scroll;
  height: calc(100% - 56px);
  flex-wrap: nowrap !important;
  padding-bottom: 10px;
}

.message-bubble-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.message-bubble {
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  font-size: 14px;
  line-height: 20px;
  padding: 8px 16px;
  margin: 8px 0;
  max-width: 80%;
}

.user-message {
  align-self: flex-start;
  background-color: #dcf8c6;
}

.chatgpt-message {
  align-self: flex-end;
  background-color: #e5e5ea;
}

.chat-input {
  border: none;
  border-radius: 16px;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 16px;
  width: calc(100% - 56px);
}

.send-button {
  background-color: #0078ff;
  border: none;
  border-radius: 16px;
  color: white;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 16px;
}

.persist-this {
  position: sticky;
  bottom: 0;
  background-color: white;
  margin-top: 0;
}

.single-line {
  font-size: 18px;
}

.multiple-lines {
  font-size: 14px;
}

.chat-header {
  background-color: black;
  border-radius: 25px 25px 0 0;
  color: white;
  padding: 16px;
  margin-right: -10px;
}