.solid-wrong-question{
    border: solid 1px black;
    background-color:#FB0006;
    color: white;
    width: 2.3em;
    height: 2em;
}

.omit-colors{
    background-color: #EBEAFF !important;
    color:#0000FF !important;
    border: 2px dashed #0000FF !important;
}
