.artful-row-after-header-extremely-small{
    background: linear-gradient(to right, rgb(1, 135, 1), rgb(0, 64, 25));
    height: 11rem;
    overflow: hidden;
    position: relative;
}

.main-about-sat-container{
    min-height: 80vh;
}
.black-text{
    color:black;
}

.subtext-font{
    font-size: 16px;
    line-height: normal;
}