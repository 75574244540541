.problemButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupContainer {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.headerRow {
  background-color: black;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
}

.difficultyRow {
  background-color: white;
  color: black;
}

.text-center {
  text-align: center;
}

.mt-2 {
  margin-top: 16px;
}

.mt-3 {
  margin-top: 24px;
}
