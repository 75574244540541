.pageWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 60px; /* Add padding to account for fixed header height */
}

.content {
  flex: 1;
  overflow-y: auto; /* Enables scrolling if content overflows */
  padding-bottom: 100px; /* Ensure the content doesn't overlap with the footer */
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px; /* Adjust height as per your footer */
  background-color: #f8f9fa;
  z-index: 1000; /* Ensures it stays on top */
}
