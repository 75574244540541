.black-border-rounded{
    border: 1px solid black;
    border-radius: 10px;
    max-width: fit-content;
    max-height: fit-content;
}

.difficulty-summary-card-body{
    margin-right: .75rem !important;
    margin-left: .75rem !important;
}