.happy-computer-image{
    max-width: 200px;
    max-height: 200px;
}

.font-awesome-vertical-shift-down{
    margin-top: .8rem;
}

.font-awesome-vertical-shift-down-two{
    margin-top: .7rem;
}

.text-size-one-point-five-rem{
    font-size: 1.2rem;
}
.black-border{
    border: 1px solid black !important;
}