.problem-topics-display{
    min-width: 9rem;
    border-radius: 25px;
}

.problem-topics-display:hover{
    background-color: rgb(0, 41, 0) !important;
}

.sub-topic-divider{
    transform: scaleY(.7);
    margin-right: 5rem !important;
}

.problem-types-topic-list li {
    list-style-type: none;
}

.problem-type-full-page-line{
    transform: scaleX(1.007);
    overflow: hidden;
}