.add-cursor-pointer:hover {
  cursor: pointer;
}

.show-button {
  border: 1px solid black;
  height: 1.4rem;
}

.red-box-around {
  border: 1px solid red;
  border-radius: 15px;
}

.green-box-around {
  border: 1px solid green;
  border-radius: 15px;
}

.blue-box-around {
  border: 1px solid blue;
  border-radius: 15px;
}

.purple-box-around {
  border: 1px solid #2d0087;
  border-radius: 15px;
}
