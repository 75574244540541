.draggable-line-reader {
  width: 100%;
  height: 50px;
  background-color: black;
  opacity: 0.85;
  color: white;
  z-index: 1000;
}

.line-reader-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.close-button-container {
  display: flex;
  align-items: center;
}

.line-reader-heading-font {
  font-size: 16px;
  font-weight: bold;
}

.add-subtle-shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
