

.nav-tabs .nav-link.active {

    background-color: #C1DAC1 !important; /* Active tab background color, e.g., Bootstrap primary blue */
}

.nav-tabs .nav-link:not(.active) {
    color: #290078; /* or your desired color */
}
