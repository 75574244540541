.annotation-cursor {
  cursor: url("highlighter_cursor_vectorized.png") 10 20, auto;
}

@media (max-width: 576px) {
  .custom-panel-one {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .custom-panel-two {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .custom-panel-one {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .custom-panel-two {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 769px) {
  .custom-panel-one {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .custom-panel-two {
    flex: 0 0 30%;
    max-width: 30%;
  }
}
