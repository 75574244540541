#common-artful-row{
    max-height: 12rem;
    min-height: 12rem;
    background: linear-gradient(to right, rgb(1, 136, 1), rgb(0, 55, 21));
    overflow: hidden;
    position: relative;
}

.artful-row-main-title{
    font-size: 4rem;
    font-weight: 600;
    color: white;
}

.main-title-font-xl{
    font-size: 6.7rem;
}
.main-title-font-lg{
    font-size: 6.6rem;
}

.main-title-font-md{
    font-size: 4.3rem;
}

.main-title-font-sm{
    font-size: 6rem;
}

.main-title-font-xs{
    font-size: 5.7rem;
}

.subtitle-font-xl{
    font-size: 2.5rem;
}
.subtitle-font-lg{
    font-size: 2rem;
}

.subtitle-font-md{
    font-size: 4.3rem;
}

.subtitle-font-sm{
    font-size: 6rem;
}

.subtitle-font-xs{
    font-size: 4.7rem;
}