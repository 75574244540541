
.card-container {
    border-radius: 0px;
    overflow: hidden;
  }
  
  .vocab-title-container {
    background-color: black;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 10px;
    border: 2px solid white;
    max-width: fit-content;
    min-width: fit-content;
  }

  .mini-vocab-title-container {
    background-color: black;
    border-radius: 20px;
    border: 2px solid gray;
    max-width: fit-content;
    min-width: fit-content;
  }

  .mini-vocab-title-container-active {
    background-color: black;
    border-radius: 20px;
    border: 2px solid rgb(255, 255, 255);
    max-width: fit-content;
    min-width: fit-content;
  }

  .vocab-title-container:hover{
    border: 3px solid white;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .vocab-description-font{
    font-size: 1.2rem;
    font-weight: 400;
  }

  .bg-color-black{
    background-color: black !important;
  }

  .expand-button-container{
    border-radius: 10px;
    border: 2px solid #6D6D6D;
  }

  .expand-button-container:hover{
    border: 3px solid white;
    border-radius: 10px;
    cursor: pointer;
  }

  .hover-green:hover{
    color: #0D5502 !important;
    cursor: pointer;
  }

  .hover-yellow:hover{
    color: rgb(184, 184, 3) !important;
    cursor: pointer;
  }

  .hover-red:hover{
    color: rgb(132, 0, 0) !important;
    cursor: pointer;
  }

  .hover-white:hover{
    color: white !important;
    cursor: pointer;
  }
  .hover-enlarge-most {
    transform: scale(1);
  }
  
  .hover-enlarge-most:hover {
    cursor: pointer !important;
    transform: scale(1.17);
    transition: transform .5s ease;
  }
  

  .bg-color-gray{
    background-color: #c0bebe !important;
  }

  .background-green-gradiant{
    background: linear-gradient(90deg, rgb(1, 89, 1), rgba(0, 30, 0, 1));
  }