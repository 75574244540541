


.logo-header-row {
    display: flex;
  align-items: flex-start;
}

.overall-navbar {
    margin: auto;
    width: 100%;
    height: 3.7rem;
    background-color: rgba(1, 102, 1, 0.02);
    /* border-bottom: 1px solid black; */
}

.user-login-icon {
  color: rgb(1, 102, 1);
  margin-left: -3px;
  transform: translateY(-1px);
}

.dropdown-toggle::after {
  display: none !important; 
}



.user-button {
   height:50px;
   width:50px;
   cursor:pointer;
   border: none;
}

.navbar-full-row{
  min-width: 100%;
  margin-right: 0px !important;
  margin-left: auto;
}

.shift-right {
  margin-right: 0px;
  margin-left: auto;
  align-items: flex-end;
  justify-content: end;
  display:flex;
}

.development-color{
  background-color: rgba(255, 0, 0, 0.103) !important;
}