/* CurrentQuestion.css */

.current-question-card {
    border-radius: 30px;
  }
.current-question {
    /* Styles for the current question container go here */
  }
  
  .question-text {
    /* Styles for the question text go here */
  }
  
  .question-choices {
    /* Styles for the question choices go here */
  }
  

  .current-question img {
    max-width: 100%;
    max-height: 100%;
  }

