/* TrainingModuleFooter.module.css */

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px; /* Adjust to your preferred height */
  z-index: 1000; /* Ensures it stays on top */
  border-top: 2px dashed black;
}

.footer-correct {
  background-color: #c9dac5 !important;
}

.footer-wrong {
  background-color: #f5cac9 !important;
}
