.new-word-modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;

}

@keyframes shimmer {
    0% { background-position: -500px 0; }
    100% { background-position: 500px 0; }
  }
  
  .sparkle-text {
    font-size: 2em; /* Adjust size as needed */
    color: #000;
    background: linear-gradient(90deg, rgba(255,255,255,0), rgba(255,255,255,0.8), rgba(255,255,255,0)) right;
    background-size: 200%;
    animation: shimmer 2s infinite linear;
  }
  