@media (max-width: 576px) {
    .modal-lg {
      transform: scale(.8);
    }
  }
  
  /* body.modal-open {
    overflow-x: scroll;
    overflow-y: hidden;
  }
   */