.deck-container {
    max-width: 150px;
    min-width: 150px;
    background-color: #000000;
    border-radius: 5px;
    overflow: hidden;
    perspective: 1000px;
    position: relative;
  }
  
  

.deck-title{
    font-size: 1.2rem;
    font-weight: 400;
    color: white;
}

.card-3d {
    transform: perspective(1000px) rotateX(-45deg);
    transform-origin: center bottom;
    transition: transform 0.5s;
  }
  
  .card-3d:hover {
    transform: perspective(1000px) rotateX(-30deg);
  }
  
  .card-stack::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(
      -45deg,
      #222,
      #222 2px,
      transparent 2px,
      transparent 6px
    );
    opacity: 0.5;
    z-index: -1;
    transform: perspective(1000px) rotateX(-45deg);
    transform-origin: center bottom;
    transition: transform 0.5s;
  }
  
  
  

  .card-stack:hover::before {
    transform: perspective(1000px) rotateX(-30deg);
  }
  
  
  .deck-contents {
    position: relative;
    z-index: 1;
  }
  
  .card-stack .deck-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  
  .card-stack .background-card {
    position: absolute;
    background-color: #222;
    border-radius: 5px;
    opacity: 0.8;
  }
  
  .hover-pointer:hover{
    cursor: pointer;
  }