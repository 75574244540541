.answer-explanation-row {
  position: fixed;
  bottom: 1.3rem;
  margin-bottom: -0.75rem;
  z-index: 50;
}

.purple-answer-text {
  background: linear-gradient(to right, #4300a7, #130030);
  border-radius: 20px 20px 0px 0px;
  /* margin-left: 3rem; */
}
.red-answer-text {
  background: linear-gradient(to right, #a00003, #3b0001);
  border-radius: 20px 20px 0px 0px;
  /* margin-left: 3rem; */
}
.green-answer-text {
  background: linear-gradient(to right, #0a4002, #041c01);
  border-radius: 20px 20px 0px 0px;
  /* margin-left: 3rem; */
}

/* Styles for expanded state */
.expanded {
}

.explanation-content {
  height: 20rem !important;
  overflow: auto;
}

.answer-explanation-purple-line {
  background: linear-gradient(to right, #4300a7, #130030);
  height: 5px;
  z-index: 9;
  width: 100vw;
  /* margin-left: 1.5rem; */
}

.answer-explanation-green-line {
  background: linear-gradient(to right, #0d5502, #052301);
  height: 5px;
  z-index: 9;
  width: 100vw;
  /* margin-left: 1.5rem; */
}

.answer-explanation-red-line {
  background: linear-gradient(to right, #b70004, #270001);
  height: 5px;
  z-index: 9;
  width: 100vw;
  /* margin-left: 1.5rem; */
}

.container-horizontal-offset {
  /* margin-left: 12px !important; */
}
