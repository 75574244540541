/* General Styles */
.user-card-small,
.user-card-large {
  position: relative;
  overflow: 'hidden';
}

.user-card-image-small,
.user-card-image-large {
  position: relative;
  z-index: 2;
}

.user-card-animate-small,
.user-card-animate-large {
  overflow: hidden;
  position: absolute;
  z-index: 1;
  background-color: white;
}

.screenshot-image-small,
.screenshot-image-large {
  width: 100%;
  object-fit: 'contain';
}

/* Specific Styles for Smaller Screens */
.user-card-small {
}

.user-card-image-small {
  max-height: 14rem;
}

.user-card-animate-small {
  top: 18px;
  left: 50px;
  max-height: 14rem;
}

.screenshot-image-small {
  max-height: 11.1rem;
}

/* Specific Styles for Larger Screens */
.user-card-large {
}

.user-card-image-large {
  max-height: 20rem;
}

.user-card-animate-large {
  top: 25px;
  left: 74px;
  max-height: 15.8rem;
}

.screenshot-image-large {
  max-height: 15.8rem;
}

/* Safari-specific adjustments */

/* You can use the _::-webkit-full-page-media, _:future, :root trick to target Safari */
/* The following code will apply only to Safari */
_::-webkit-full-page-media, _:future, :root .user-card-animate-small {
  /* Your adjustments for Safari here */
 max-width: 70%;

}

_::-webkit-full-page-media, _:future, :root .user-card-animate-large {
  /* Your adjustments for Safari here */
  max-width: 70%;
}
