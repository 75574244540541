.review-mode-video-icon {
  height: 2rem;
  width: 3rem;
  filter: brightness(100%) invert();
}

.review-mode-video-icon-container {
  border-radius: 50px;
}

.hide-button .dropdown-toggle::after {
  display: none !important;
}

.pulsing-shadow {
  position: relative;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #290078;
  }
  50% {
    box-shadow: 0 0 5px 2px #290078;
  }
  100% {
    box-shadow: 0 0 0 0 #290078;
  }
}

.motion-div-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.bg-color-main-purple {
  background-color: #2d00873b !important;
}
