/* https://stackoverflow.com/questions/4575826/how-to-push-a-footer-to-the-bottom-of-page-when-content-is-short-or-missing */

.flex-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 10rem;
  max-height: 80vh;
  overflow-y: scroll;
}

.main-practice-module-container {
  overflow: hidden !important;
  position: relative;
  min-height: 100vh;
  max-height: 100vh;
}

#dropdown-button-drop-up {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}
