.all-computer-tests-tabs {
    display: flex;
    justify-content: space-around;
  }
  
  .tab-option {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
  }
  
  .tab-option.active {
    color: #230070;
    font-weight: bold;
    background-color: #E7E7E7;
  }
  
  .tab-content-area {
    background-color: rgba(211, 211, 211, 0.43);
    margin: 0;
    min-height: 65vh;
  }

  .tab-option:hover {
    background-color: rgba(211, 211, 211, 0.43);
    cursor: pointer;
  }
  
  .active:hover {
    background-color: rgba(211, 211, 211, 0.43);
    cursor: pointer;
  }

.tab-option:active{
    background-color: rgba(165, 164, 164, 0.875);
    cursor: pointer;
}

.small-text{
    font-size: 1rem;
}
  
.text-dark-gray{
    color: rgb(95, 95, 95);
}