.thin-black-line{
    height: .4rem;   
    background-color: black;
}

.main-navigation-header {
    height: 4.2rem;
}

.artful-row-after-header{
    background: linear-gradient(to right, rgb(1, 109, 1), rgb(0, 43, 17));
    height: 27rem;
    overflow: hidden;
    position: relative;
}

.tiled-image-welcome{
    height: 5rem;
    width: 5rem;
    opacity: 8%;
    filter: brightness(100%) invert();
    position: absolute;
}

.hover-enlarge-more {
    transition: transform 0.3s ease;
  }

.hover-enlarge-more:hover{
    cursor: pointer !important;
    -webkit-transform: scale(1.03);
    transition: transform .5s ease;
}

.company-logo:hover {
    -webkit-transform: scale(1.02);
    cursor: pointer;
}

.logo-image{
    align-items: flex-start;
    height: 4.5em;
    width: 4.5em;
    padding-left: 0px;
    margin-left: 10px;
    
}

.user-login-icon{
    font-size: 2.5rem;
    color: black;
}

.row-positioned-above-artful {
    position: absolute;
    height: 100%;
    color: white;
}

.artful-row-of-images{
    position: absolute;
    height: 100%
}

.sign-in-card {
    min-height: 16rem;
    min-width: 40rem;
    border-radius: 25px !important;
}

.welcome-message-intro {
    font-size: 4rem;
}

.sign-in-button-card {
    font-size: 1.5rem !important;
    max-width: 15rem !important;
    background-color: rgb(1, 102, 1) !important;
    margin-top: 5rem !important;
}


.create-account-link {
    color: black;
}

.bg-color-offwhite {
    background-color: rgb(238, 238, 237);
}

.circle-icon-container {
    background-color: rgb(1, 102, 1);
    border-radius: 50%;
    max-width: max-content;
}

.navigation-card {
    max-height: 22rem;
    min-height: 18rem;
    border-radius: 10% !important;
    -webkit-box-shadow: 0px 0px 19px -6px rgba(0,0,0,0.77); 
    box-shadow: 0px 0px 19px -6px rgba(0,0,0,0.77);
}

.navigation-card-icon{
    height: 5rem;
    width: 5rem;
    filter: brightness(100%) invert();
}



.empty-row-placeholder{
    height: .01rem;
    background-color: white;
}



.overall-sign-in-card{
    margin-left: 20px !important;
}

.row-within-sign-in {
    
    box-shadow: none;
}

.background-green {
    background-color: rgb(1, 102, 1) !important;
}

.background-blue {
    background-color: rgb(0, 11, 158) !important;
}

.background-aqua {
    background-color: rgb(0, 110, 101) !important;
}

.background-pink {
    background-color: rgb(123, 0, 112)!important;
}

.background-purple {
    background-color: rgb(58, 2, 118) !important;
}

.agenda-card {
    border-radius: 15px;
}

.agenda-card:hover{
    cursor: pointer;
    -webkit-transform: scale(1.02);
}

.add-shadow{
    -webkit-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.45); 
box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.45);
}

.beta-launch-modal{
    min-width: 800px !important;
    min-height: 500px !important;
}

.nunito-font {
    font-family: 'Nunito', sans-serif;
}

.main-font{
    font-family: 'Source Sans Pro', sans-serif;
}

.purple-button{
    background-color: #37008B !important;
    border: 1px solid  rgb(18, 0, 50) !important;
}

.red-button{
    background-color: rgb(198, 0, 0) !important;
    border: 1px solid  rgb(115, 0, 0) !important;
}

.red-button:hover{
    background-color: rgb(247, 0, 0) !important;
  }

.purple-text{
    color: #37008B;
}

.text-purple {
    color: #37008B;
  }

  .description-text{
    font-size: 1.5rem;
    color: black;
    background-color: white;
    border-radius: 10px;
    border:#37008B 1px dashed; 
    padding: 10px;
  }

  .amatic-font{

  font-family: 'Amatic SC' ;
    }

    

    .font-weight-very-bold{
        font-weight: 900 !important;
    }

    .main-digital-sat-font-xl{
        font-size: 6.5rem;
    }
    .main-digital-sat-font-lg{
        font-size: 5.3rem;
    }

    .main-digital-sat-font-md{
        font-size: 4.3rem;
    }

    .main-digital-sat-font-sm{
        font-size: 6rem;
    }

    .main-digital-sat-font-xs{
        font-size: 4.7rem;
    }

    .purple-dashed-border{
        border: 2px dashed #290078 !important;
        border-radius: 10px;
    }