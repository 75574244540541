.test-list-table-font{
    font-size: 1rem;
}

.overall-scales-list-container{

    margin-top: -1rem;

}

.dark-gray-button{
    background-color: rgb(76, 76, 76);
    border: 1px solid rgb(64, 64, 64);
}


