.main-practice-modules-container{
    min-height: 45vh;
    min-width: 100vw;
}

.most-recent-practice-module-card{
    width: fit-content;
    border: none !important;
}

.focused-practice-recent-container{
    border-radius: 25px;
    max-width: 22rem;
}

.max-width-fit-content{
    max-width: fit-content !important;
}

#practice-module-artful-row{
    max-height: 18rem;
}

.bg-color-black{
    background-color: black !important;
}

.box-around-div {
    border: 1px solid black;
}

.test-review-card {
    -webkit-box-shadow: -2px -6px 15px -3px rgba(0,0,0,0.44); 
box-shadow: -2px -6px 15px -3px rgba(0,0,0,0.44);
}

.add-subtle-shadow{
    -webkit-box-shadow: 0px 0px 17px 1px rgba(0,0,0,0.22); 
    box-shadow: 0px 0px 17px 1px rgba(0,0,0,0.22);
}

.hover-enlarge {
    transition: transform 0.3s ease;
  }
  
  .hover-enlarge:hover {
    cursor: pointer !important;
    transform: scale(1.01);
    transition: transform .5s ease;
  }
  

.practice-module-card {
    width: 100%;
}
.module-stats-small{
    font-size: 1rem;
}

.bg-color-purple{
    background-color: rgba(128, 0, 128, 0.159);
}

.bg-color-white{
    background-color: white;
}

.selected-column{
    -webkit-transform: scale(1.02);
    transform: translateY(2px);
    z-index: 2;
}

.tabbed-hr{
    background-color: rgba(152, 152, 152, 0.174) !important;
}

.row-for-practice-modules{
    margin-left: -20px !important;
    margin-right: -20px !important;
    
    min-width: 100vw !important;
    
}



.pink-button{
    background-color: rgb(138, 47, 143) !important;
    border: 1px solid  rgb(27, 0, 24) !important;
}

.orange-button{
    background-color: rgb(175, 63, 2) !important;
    border: 1px solid  rgb(68, 27, 0) !important;
}

.blue-button{
    background-color: rgb(3, 143, 143) !important;
    border: 1px solid  rgb(0, 50, 50) !important;
}

.green-button{
    background-color: rgb(1, 102, 1) !important;
    border: 1px solid  rgb(0, 41, 0) !important;
}

.green-button:hover{
    background-color: rgb(1, 111, 1) !important;
    border: 1px solid  rgb(0, 41, 0) !important;
}

.gray-button{
    background-color: rgb(112, 112, 112) !important;
    border: 1px solid  rgb(40, 40, 40) !important;
   
}

.black-button{
    background-color: rgb(0, 0, 0) !important;
    border: 1px solid  rgb(40, 40, 40) !important;
}

.black-button:hover{
    background-color: rgb(40, 40, 40) !important;
    border: 1px solid  rgb(40, 40, 40) !important;
}