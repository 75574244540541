.timing-progress-bar{
    border: 2px solid black;
   border-radius: 25px;
    height: 1rem;
    overflow: hidden;
}

.results-modal-body {
    /* max-height: 66vh; */
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.021);
}

.module-results-summary-modal {
    max-height: 90vh;
    
}

.timing-progress-bar-green {
    background-color: green;
    height: 103%;
    display: inline-block;
    transform: translateY(-5px);
   
    
}

.timing-progress-bar-yellow {
    background-color: yellow;
    height: 103%;
   
    display: inline-block;
    transform: translateY(-5px);
}

.timing-progress-bar-red{
    background-color: red;
    height: 103%;
    
    display: inline-block;
    transform: translateY(-5px);
}

.student-question-timing-pin{
    margin-left: 35%;
}

.green-text{
    color:green;
}
.red-text{
    color:red;
}
.gray-text{
    color:#6b6b6b;
}
.background-color-white{
    background-color: white;
}

.per-question-results-table{
    max-width: 50rem !important;
}

.module-results-footer{
    max-height: 10px !important;
    height: 1rem !important;
}

.add-even-shadow{
    -webkit-box-shadow: 0px 0px 20px 3px rgba(0,0,0,0.16); 
box-shadow: 0px 0px 20px 3px rgba(0,0,0,0.16); 
}

.module-results-summary-modal .modal-content {
    background-color: rgb(240, 239, 239);
  }

  .modal-header--sticky {
    position: sticky;
    width: 100%;
    top: 0;
    background-color: inherit; /* [1] */
    z-index: 1055; /* [2] */
  }

  .modal-footer--sticky {
    position: sticky;
    width: 100%;
    min-height: 6.2rem;
    bottom: 0;
    background-color: inherit; /* [1] */
    z-index: 1055; /* [2] */
  }

  .module-results-summary-modal .modal-content .modal-footer--sticky {
    background-color: white;
    -webkit-box-shadow: 0px -8px 14px -4px rgba(0,0,0,0.4); 
    box-shadow: 0px -8px 14px -4px rgba(0,0,0,0.4);
  }

  .module-results-summary-modal .modal-content .modal-header--sticky {
    background-color: white;
    -webkit-box-shadow: 0px 2px 12px 1px rgba(0,0,0,0.4); 
box-shadow: 0px 2px 12px 1px rgba(0,0,0,0.4);
  }