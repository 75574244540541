#learn-video-player-container {
border: solid 5px #0D5502;
border-width: 5px 3px 3px 5px;
border-radius:95% 4% 97% 5%/4% 94% 3% 95%;
transform: rotate(2deg);
position: relative;
padding-bottom: 56.25%; 
height: 0; 
overflow: hidden;
background-color: black;
}

