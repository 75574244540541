.scale-hover:hover {
  scale: 1.1;
}

.highlight-red{
  border: 1px solid red;
  background-color: rgba(255, 0, 0, 0.111);
}

.highlight-yellow{
  border: 1px solid yellow;
  background-color: rgba(255, 255, 0, 0.241);
}

.highlight-green{
  border: 1px solid green;
  background-color: rgba(0, 255, 0, 0.241); 
}

.hover-purple:hover{
  cursor: pointer;
  color:rgb(84, 30, 134);
}

.hover-enlarge-text-3-2-rem:hover{
  font-size: 1.1rem;
  cursor: pointer;
}

.bg-color-light-blue{
  background-color: rgba(92, 92, 255, 0.234) !important;
  border: 1px solid rgb(0, 0, 255) !important;
}