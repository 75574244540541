.main-sign-in-page{
    min-height: 100vh;
    margin-top: 5px;
    overflow: hidden;
}

.sign-in-card{
    
    border-radius: 10%;
    -webkit-box-shadow: 0px 0px 19px -6px rgba(0,0,0,0.77); 
    box-shadow: 0px 0px 19px -6px rgba(0,0,0,0.77);
}

.circular-logo-container{
    background-color: rgb(1, 102, 1);
    max-width: fit-content;
    border-radius: 50%;
    -webkit-box-shadow: 0px 0px 19px -6px rgba(0,0,0,0.77); 
    box-shadow: 0px 0px 19px -6px rgba(0,0,0,0.77);
}

.circular-logo-image{
    height: 7rem;
    width: 7rem;
}

.sign-in-card-header{
    background-color: white !important;
    border: none !important;
}

.sign-in-button{
    background-color: rgb(1, 102, 1) !important;
}