

.test-results-smaller-font{
    font-size: .9rem;
}

.test-results-smallest-font{
    font-size: .8rem;
}

.act-section-subscore{
    font-size: 2rem;
}

.act-composite-score {


}