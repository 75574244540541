.autocomplete-container{
    max-height: 10rem;
    max-width:fit-content;
    min-width: 12rem;
    overflow: scroll;
    position:absolute;
    background-color: white;
    border: 1px solid black;
    border-radius: 20px;
    margin-left: -1rem;
}

.type-search-input-field{
    border-radius: 10px;
    height: 2.2rem;
    min-width: 8rem;
}