#test-scale-footer-row{

        position: absolute;
        bottom: 0;
        width: 100%;
        height: 4rem;
        background: linear-gradient(to right, rgb(1, 136, 1), rgb(0, 55, 21));

}

.test-scales-top-row {
    background: linear-gradient(to right, rgb(1, 136, 1), rgb(0, 55, 21));
}

.test-scales-autocomplete .test-name-autocomplete-container {
    transform: translateY(40px);
}

.raw-score-input-field {
    max-width: 2.5rem;
    border-radius: 10px;
    border: 1px solid gray;
}

.test-scales-section-header-card{
    max-width: 20rem;
}

.main-scales-container-scroll{
    overflow-y: scroll;
    scale: .85;
    max-height: 70vh;
    margin-top: -1.2rem !important;
}

.shift-scaled-heading-left{
    margin-left: -5px;
}


/* The code below hides the bars for the number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.orange-border{
    border: 3px solid orange;
}