.annotation-side-tray {
  background-color: #edebeb;
  min-width: 20px;
  border-left: #c1c1c1 2px solid;
  border-bottom: #c1c1c1 2px solid;
  overflow-y: scroll;
}

.annotation-side-tray-button {
  position: sticky;
  border: 2px solid #c1c1c1;
  border-radius: 15px 0px 0px 15px;
  color: white;
  background-color: #585858;
  padding-right: 8px;
  padding-top: 3px;
}
