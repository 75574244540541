.preserve-whitespace {
  white-space: pre-wrap; /* or pre-line */
}

/* .mark-tooltip {
    position: relative;
    display: inline-block;
  }
  
  .mark-tooltip::after {
    content: attr(data-tooltip-content);
    position: absolute;
    display: none;
    background-color: #fff;
    color: #333;
    border: 3px solid #333;
    border-radius: 5px;
    padding: 0px 16px 0px 16px;
    font-size: 20px;
    white-space: nowrap;
    z-index: 10;
    opacity: 1;
    pointer-events: none;
    transform: translateY(-100%);
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    text-decoration: none;
  }
  
  .mark-tooltip:hover::after {
    display: block;
    text-decoration: none;
  } */
