.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30px, 1fr));
    gap: 2px;;
  }
  
  .grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    position: relative;
  }
  
  .grid-header {
    font-weight: bold;
    font-size: 12px;
  }
  
  .sr {
    
  }
  
  .horizontal-line {
    height: 1px;
    background-color: black;
    margin-top: 2px;
  }
  

  .problem-row {
    margin-bottom: 1rem;
  }
  
  .grid-item {
    text-align: center;
    max-width: 8px !important; /* Set a fixed width for each grid item */
  }
  
  .grid-header {
    white-space: nowrap; /* Add this to prevent wrapping */
  }



  .thin-gray-border{
    border: .5px solid gray;
  }

  .rounded-border-purple{
    border: 2px solid #290078;
    border-radius: 150px;
  }