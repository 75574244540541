.student-answer{
    max-width: 3rem;
    min-width: 3rem;
}

.background-color-red{
    background-color: rgba(255, 0, 0, 0.043);
}

.score-control-box{
    max-width: 5em;
}