.test-name-autocomplete-container{
    max-height: 10rem;
    max-width:fit-content;
    min-width: 12rem;
    overflow: scroll;
    position:absolute;
    bottom: 7rem;
    background-color: rgb(255, 255, 255);
    border: 1px solid black;
    border-radius: 20px;
    margin-left: 0rem;
    
}

.type-search-input-field{
    max-width: 30%;
}

.test-name-search-button{
    
    font-size: .8rem;
}