.panel {
  position: absolute;
  bottom: 70px; /* Adjust based on your footer's height */
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: rgb(0, 60, 5) solid 1px;
  overflow: hidden;
  width: 90vw; /* Adjust the width of the panel */
  max-width: 500px; /* Set a maximum width */
  z-index: 10; /* Ensure it's on top of other content */
  max-height: 85vh; /* Set a maximum height */
  overflow: auto;
}
