.logo-container {
    position: relative;
  }
  
  .logo-circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: absolute;
  }
  
  .logo-green {
    background-color: #014103;
    opacity: 0.9;
    top: 0;
    left: 0;
    z-index: 4;
  }
  
  .logo-purple {
    background-color:  rgb(48, 0, 48);
    opacity: 0.8;
    top: 50px;
    left: 50px;
    z-index: 3;
  }
  
  .logo-black {
    background-color: black;
    opacity: 0.7;
    top: 0px;
    left: 100px;
    z-index: 5;
  }
  
  .logo-title {
    position: absolute;
    font-size: 34px;
    font-weight: bold;
    color: white;
    text-align: center;
    top: 85px;
    left: 75px;
    width: 150px;
    z-index: 6;
  }
  
  .logo-circle {
    animation: expandCircle 0.5s ease-in-out;
  }
  
  @keyframes expandCircle {
    from {
      width: 0px;
      height: 0px;
      border-radius: 50%;
      top: 100px;
      left: 100px;
    }
    to {
      width: 200px;
      height: 200px;
      border-radius: 50%;
    }
  }
  