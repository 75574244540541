.school-input-header{
    background: linear-gradient(to right, rgb(2, 176, 2), rgb(0, 64, 25));
}

.text-stroke{
    -webkit-text-stroke: 1px rgb(0, 0, 0);
}

.long-input-field{
    min-height: 5rem;
}

.college-numerical-input{
    max-width: 3rem;
}

.college-longer-paragraph{
    min-height: 5rem;
}