
.timestamp-button {
  background-color: #006003; /* Green background */
  border: none; /* Remove border */
  color: white; /* White text */
  padding: 5px 10px; /* Some padding */
  text-align: center; /* Center text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Make the button inline */
  font-size: 16px; /* Set a font size */
  margin: 4px 2px; /* Add some space between buttons */
  cursor: pointer; /* Add a pointer cursor on hover */
  border-radius: 5px; /* Rounded corners */
}

.timestamp-button:hover {
  background-color: #003e02; /* Darken the background color on hover */
}

.active-topic-button {
    background-color: rgb(80, 0, 80) !important;
  }
  
  