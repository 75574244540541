.fuzzy-search-data-result{
    margin-top: 5px;
    width: 300px;
    height: 300px;
    background-color: white;
    box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;
    overflow:hidden;
    overflow-y: auto;
    padding: 6px 6px 6px 6px;
}

.data-item.selected {
    background-color: #f2f2f2;
}

.fuzzy-search-input{
    border-radius: 7px;
    border: 1px solid #d3d2d2;
    padding: 7px 7px 7px 7px;
}