.edit-problem-footer {
    position : fixed;
    bottom   : 0;
    width: 101%;
    height: 7rem; /* This must still linked with padding-bottom above. */
    background-color: rgb(0, 0, 0);
    z-index: 100;
}

.container-above-edit-problem-footer {
    padding-bottom: 10rem; /* This must stay linked with footer height below */
    overflow-y: auto;
    height: 100vh;
}