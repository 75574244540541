th{
    font-weight: normal;
}

.font-weight-bold{
    font-weight: bold;
}

*{
    font-family: "Times New Roman", Times, serif;
  }

  .main-test-result-container{
    min-height: 70vh;
  }

  .full-sat-results-header-card{
    max-width: fit-content;
    border-radius: 15px !important;
    /* background-image: linear-gradient(to right, rgb(1, 135, 1), rgb(0, 64, 25)); */
    background: linear-gradient(to right, rgb(94, 0, 177), rgb(30, 0, 57));
  }
  
  .main-sat-history-table{
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 1.6rem;
    font-family: sans-serif;
    min-width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }

  .main-sat-history-table thead tr {
    /* background: linear-gradient(to right, rgb(1, 135, 1), rgb(0, 64, 25)); */
    background: linear-gradient(to right, rgb(94, 0, 177), rgb(30, 0, 57));
    color: #ffffff;
    text-align: left;
}

.main-sat-history-table th,
.main-sat-history-table td {
    padding: 0px 15px;
    
}

.main-sat-history-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.main-sat-history-table tbody tr:nth-of-type(even) {
    background-color: #f7f7f7;
}

.main-sat-history-table tbody tr:last-of-type {
    border-bottom: 2px solid rgb(0, 64, 25);
}

.main-sat-history-table tbody tr.active-row {
    font-weight: bold;
    color: #0c6900;
}

.main-sat-history-table tr td {
    border-right: 1px solid rgba(30, 0, 57, 0.045);
    color: black
  }
  
  .main-sat-history-table tr td:last-of-type {
    border: none;
  }

  .test-results-not-smallest-font{
    font-size: 1.5rem;
  }

  .sat-score-font-size{
    font-size: 2.0rem
  }

 