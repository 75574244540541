.main-problem-container {
  white-space: pre-wrap;
  margin: auto;
  height: 95vh;
  zoom: 80%;
}

.problem-number-header-row {
  background-color: rgba(204, 203, 203, 0.518);
  height: 2.7rem;
  width: 100%;
}

.problem-number {
  background-color: black;
  color: white;
}

.box-around-bookmark {
  background-color: white;
  border-radius: 5px;
  border: 1px solid black;
}

.box-around-bookmark:hover {
  cursor: pointer;
}

.numberCircle {
  display: inline-block;
  line-height: 0px;
  border-radius: 50%;
  border: 2px solid;
  font-size: 32px;
}

.numberCircle span {
  display: inline-block;
  padding-top: 50%;
  padding-bottom: 50%;
  margin-left: 8px;
  margin-right: 8px;
}

.bookmark-inactive {
  color: black;
}

.bookmark-active {
  color: rgb(1, 102, 1);
}

.box-around-answer-choices {
  border-radius: 8px;
  border: 1px solid black;
}

.box-around-answer-choices:hover {
  cursor: pointer;
  border: 3px solid green;
}

.circle-letter-answer {
  /* -webkit-text-stroke: 1px black; */
}

.grid-in-box {
  min-height: 2rem;
}

.time-remaining-alert {
  font-size: x-large;
}

.grid-in-form {
  width: 5rem;
  font-size: 2rem !important;
}

.selected-answer {
  background-color: rgba(0, 128, 0, 0.216);
}

.answer-choice-letter {
}

.line-through {
  text-decoration: Line-Through;
}

.line-through-cross-off {
  position: relative;
}

.line-through-cross-off:after {
  position: absolute;
  left: 0;
  top: 50%;
  margin-left: 20%;
  margin-right: 20%;
  height: 1px;
  background: #c00;
  content: "";
  width: 60%;
  display: block;
}

.line-through-cross-off-full-answer {
  position: relative;
  color: grey;
}

.line-through-cross-off-full-answer:after {
  position: absolute;
  left: 0;
  top: 45%;
  margin-left: 0%;
  margin-right: 80%;
  height: 2px;
  background: #c00;
  content: "";
  width: 100%;
  display: block;
}

.undo-button {
  text-decoration: underline;
}

.sat-answer-choice-image {
  height: 15rem;
  width: 15rem;
}

.sat-main-problem-image {
  height: 30rem;
  width: 30rem;
}

.cross-off-button-inactive {
  background-color: rgb(0, 0, 0) !important;
}

.cross-off-button-active {
  background-color: rgb(1, 102, 1);
}

.bg-color-red {
  background-color: rgba(204, 0, 0, 0.189);
}

.cross-off-button-active.bg-color-bright-red {
  background-color: red;
}
.cross-off-button-active.bg-color-green {
  background-color: rgb(1, 102, 1);
}

.bg-color-green {
  background-color: rgba(1, 102, 1, 0.201) !important;
}

.bg-color-yellow {
  background-color: rgba(223, 208, 0, 0.201) !important;
}

.bg-color-dark-gray {
  background-color: rgb(137, 137, 137) !important;
}

.bg-color-blue {
  background-color: rgba(0, 0, 255, 0.062);
}
.cross-off-button-active.bg-color-blue {
  background-color: rgb(1, 25, 102);
}

.text-red {
  color: red;
}

.text-blue {
  color: rgb(68, 68, 255);
}

.show-correct-placeholder {
  min-width: 3.5rem;
}

@media (min-width: 768px) {
  .show-correct-placeholder {
    min-width: 3.5rem; /* For medium screens and larger */
  }
}

.answer-preview-font {
  font-size: 2rem;
}

.bg-color-dark-green {
  background-color: rgb(0, 91, 0) !important;
}

.stats-button {
  position: fixed;
  right: 0px; /* Adjust distance from the right edge */
  bottom: 4.2rem; /* Start at the vertical center */
  transform: translateY(-50%); /* Center the button vertically */
  z-index: 50; /* Ensure it's above other content */
  padding: 5px 10px;
  border: none;
  color: white;
  border-radius: 15px 15px 0px 0px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center; /* Center align the text */
  justify-content: center; /* Center content vertically */
}

.stats-button:hover {
  transform: scale(1.02) translateY(-50%); /* Ensure scaling doesn't affect vertical alignment */
}

.purple-stats-button {
  background-color: #180146;
}

.purple-stats-button:hover {
  background-color: #32009e;
}

.green-stats-button {
  background-color: #081a02;
}

.green-stats-button:hover {
  background-color: #103404;
}

.red-stats-button {
  background-color: #200002;
}

.red-stats-button:hover {
  background-color: #4d0106;
}

.tooltip-custom {
  position: relative;
  display: inline-block;
}

.tooltip-custom .tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the icon */
  left: 50%;
  margin-left: -80px;
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;
}

.tooltip-custom .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip-custom:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
