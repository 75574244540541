.importance-bar {
    width: 5px !important;
    max-width: 5px !important;
  }
  
  .importance-bar:first-child {
    margin-left: 0;
  }
  
  .importance-bar:not(:last-child) {
    margin-right: 2px;
  }
  
  .filled-in-bar {
    background-color: #34009C;
  }
  
  .empty-bar {
    background-color: #888;
  }
  
  .filled-in-bar-active {
    background-color: white;
  }

  .filled-in-bar:hover {
    background-color: white;
  }