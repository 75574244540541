.reference-sheet-shape {
  max-width: 150px;
}

.handle-close-button {
  z-index: 1000;
}

.reference-sheet-containter {
  position: fixed;
  z-index: 1000;
  background-color: black;
  border-radius: 5px;
}

.reference-sheet-modal-body {
}
