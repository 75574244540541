/* Custom CSS file e.g. customStyles.css */

/* for extra-small screens */
.custom-table-text {
    font-size: 0.65rem; /* Adjust the font size as needed */
}

/* for small screens (min-width: 576px) */
@media (min-width: 576px) {
    .custom-table-text {
        font-size: 1.2rem; /* Adjust the font size as needed */
    }
}

/* for medium screens (min-width: 768px) */
@media (min-width: 768px) {
    .custom-table-text {
        font-size: .75rem; /* Adjust the font size as needed */
    }
}

/* for large screens (min-width: 992px) */
@media (min-width: 992px) {
    .custom-table-text {
        font-size: 1.1rem; /* Adjust the font size as needed */
    }
}

/* for extra-large screens (min-width: 1200px) */
@media (min-width: 1200px) {
    .custom-table-text {
        font-size: 1.1rem; /* Adjust the font size as needed */
    }
}
