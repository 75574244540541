.sticky-header {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
    z-index: 100; /* optional: set z-index to make sure the header */
}  

.scrollable-content {
    overflow-y: scroll;
    background-color: white;
    margin-bottom: 5rem; /*linked with the footer height*/
  }
  
  .MathJax {
    /* font-size: 1rem; */
    }

    .highlight {
      display: inline;
      background-color: yellow;
      font-weight: bold;
    }
   