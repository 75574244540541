.answer-choices-spacer {
  margin-bottom: 7rem;
  padding-bottom: 7rem;
}

/* adjust for xs screens */
@media (max-width: 575.98px) {
  .answer-choices-spacer {
    margin-bottom: 3rem;
    padding-bottom: 9rem;
  }
}
