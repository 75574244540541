.artful-row-after-header-extra-small-heading{
    max-height: 8rem;
    min-height: 8rem;
    width: 100vw;
    background: linear-gradient(to right, rgb(1, 136, 1), rgb(0, 55, 21));
    overflow: hidden;
    position: relative;
}

.main-container-for-grading-tests{
    min-height: 75vh;
}

.answer-choice-entry{
    max-width: 1.2rem;
    max-height: 1.2rem;
    border: none;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgba(128, 128, 128, 0.483);
}

.answer-choice-entry-gridin{
    max-width: 4rem;
    max-height: 1.2rem;
    border: none;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgba(128, 128, 128, 0.483);
}

.answer-choice-entry-card{
    max-width: fit-content;
}

.switch-uppercase{
    text-transform: uppercase;
}

.grade-test-section-header-card{
    
    border-radius: 50px !important;
}

.grade-test-section-container{
    
}

.card-containing-answer-columns{
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    border-radius: 50px !important;
}

.border-radius-50{
    border-radius: 50px !important;
}

.width-fit-content{
    max-width: fit-content;
}

.grade-test-bottom-card{
    min-height: 7rem !important;
}

.manual-test-input{
    border: 1px solid gray
}

.highest-z-value{
    z-index: 1000;
}