.overall-input-object {

}

.custom-checkbox {
    margin-right: 5px;
}

.load-underlined-button{
    margin-right: 5px;
}

.top-row-button {
    margin-top: 0;
    white-space: nowrap;
    align-items: flex-end;

    text-align:center;
    line-height:.75 rem;
    font-size:.75 rem;
    
}

.section-number-form {
    max-width: 71px !important;
}
.problem-number-form {
    max-width: 71px !important;
}
.pretty-math-display {
    height: 15.9em;
}

.navigation-arrows {
    margin-bottom: 3px;
}

.problem-type-form{
    min-width: 150px !important;
}

.subtype-form {
    max-width: 75px;
}