#tests-artful-row-small{
    max-height: 12rem;
    min-height: 12rem;
    background: linear-gradient(to right, rgb(1, 136, 1), rgb(0, 55, 21));
    overflow: hidden;
    position: relative;
}

.tests-intro{
    font-size: 7rem;
    
}

.text-shadow{
    text-shadow: 2px 2px 2px black;
}

.icon-computer{
    
        -webkit-filter: invert(100%); /* Safari/Chrome */
        filter: invert(100%);
   
}

