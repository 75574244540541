.digital-sat-curves-container{
    max-height: 80vh;
    overflow: scroll;
}

.vertical-title {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    white-space: nowrap;
  }
  
  .cell-width {
    width: 3ch;
    min-width: 3ch;
    max-width: 10px !important;
    text-align: center;
  }
  