.main-blogpost-container h2 {
    margin: 1rem 0;
    background-color: #094B00;
    color: white;
    padding: 0.5rem;
    text-align: center;
 
  }
  
  .main-blogpost-container h1 {
    margin: 1rem 0;
    color: black;
    padding: 0.5rem;
    text-align: center;
    border-radius: 5px;
  }

  .main-blogpost-container img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 576px) {
    .main-blogpost-container p {
      font-size: 1.25rem; /* Example size, adjust as needed */
    }
    .main-blogpost-container li {
      font-size: 1.25rem; /* Example size, adjust as needed */
    }
  }

  