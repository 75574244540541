.common-card-footer{
    background-color: white;
}

.common-card-logo{
    width: auto;
    height: 1.5rem;
}

.common-card-main-title{
    background: rgb(35,0,112);
    background: linear-gradient(180deg, rgba(35,0,112,0.5367010946327684) 0%, rgba(35,0,112,0.7061926200564972) 46%, rgba(2,0,36,0.5621248234463276) 74%, rgba(255,255,255,0) 100%);
    color: white;
    text-align: center;
    padding: 1rem 0;
}


/* Add your existing CSS rules here */

.card-custom {
    overflow: hidden;
    
    box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
  }
  
  .overflow-scroll {
    overflow: scroll;
  }

  .card-custom-img {
    height: 101px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-color: inherit;
    opacity: 1;
    min-width: 100px;
  }
  
  .common-card-container {
    min-height: 350px;
    position: relative;
    max-width: 550px;
  }

  
  
  .card-custom-img::after {
    position: absolute;
    content: '';
    top: 81px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-top-width: 40px;
    border-right-width: 0;
    border-bottom-width: 0;
    border-left-width: 300px;
    border-left-width: calc(575px - 5vw);
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: inherit;
    background-repeat: no-repeat;
    background-size: 100% 2px;
    background-position: left bottom;
    z-index: 1;
  }

  .green-gradient {
    background: linear-gradient(to right, #002903, #006925, #003309);
  }
  
  
.skew-line{

}

 .z-index-10{
    z-index: 10;
  }
  .z-index-5{
    z-index: 5;
  }
  
  .card-custom-avatar img {
    border-radius: 50%;
    box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
    position: absolute;
    top: 41px;
    left: 1.25rem;
    width: 80px;
    height: 80px;
    background-color: black;
    z-index: 5;
    opacity: 1;
    border: 3px #37008B solid;
  }
  
  .common-card-footer{
    position: absolute;
    bottom: 0;
  }

  .common-card-footer {
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  
  /* Add this to the CommonCard.css file */
.ribbon {
  position: absolute;
  right: -7.6rem;
  top: 1.6rem;
  z-index: 9;
  width: 20rem;
  padding: 15px;
  height: 1.5rem;
  overflow: hidden;
  background-color: #230070;
  text-align: center;
  transform: rotate(45deg);
  color: white;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled-overlay{
  position: relative;
}

.disabled-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(139, 139, 139, 0.4); /* Adjust the opacity as needed */
  z-index: 9; /* Make sure the overlay appears above the content */
  pointer-events: none; /* Allow mouse events to pass through */

}