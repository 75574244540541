/* LearnIndividualProblemType.css */

.learnIndividualProblemType {
    font-family: Arial, sans-serif;
    color: #333;
    margin: 2rem;
    padding: 1rem;
    border: 1px solid #333;
    border-radius: 10px;
    background-color: #f9f9f9;
  }
  
  .header-line {
    width: 100%;
    height: 2px;
    background-color: #290078;
    margin-bottom: 1rem;
  }
  
  .text-center {
    text-align: center;
  }
  
  .overview-section {
    margin: 2rem 0;
    border-style: dashed;
    padding: 1rem;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .overview-section h3 {
    color: #290078;
    margin-bottom: 1rem;
  }
  
  .subtypes-section {
    margin: 2rem 0;
  }
  
  .subtypes-section h3 {
    color: #290078;
    margin-bottom: 1rem;
  }
  
  .subtypes-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .subtypes-section li {
    margin-bottom: 0.5rem;
  }
  
  .video-section {
    margin: 0rem 0;
  }
  
  .video-section h3 {
    color: #290078;
    margin-bottom: 1rem;
  }
  
  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 10px;
    height: 0;
    overflow: hidden;
  }
  
  .video-container iframe,
  .video-container object,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .practice-section {
    margin: 2rem 0;
  }
