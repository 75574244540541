.time-icon-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Adjust the height as needed */
}

.absolute-center {
    position: absolute;
}


.bg-color-dark-purple {
    background-color: #4300a7;
}