.chalk-white-line-row-thin {
  position: relative;
  height: 4px;
  width: 100%;
  background-color: transparent;
  border-bottom: solid 4px white;
  border-radius: 50px / 25px;
  padding: 0;
}

.practice-module-scrollable-content {
    overflow-y: scroll; /* Vertical scrollbar */
    height: 24rem; /* Adjust as needed */
  
    /* Always show scrollbar */
    scrollbar-width: thin; /* For Firefox */
  }
  
  /* For Chrome, Safari, and Opera */
  .practice-module-scrollable-content::-webkit-scrollbar {
    width: 5px; /* Adjust as needed */
  }
  
  .practice-module-scrollable-content::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  
  .practice-module-scrollable-content::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  .practice-module-scrollable-content::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  