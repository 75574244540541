
.colleges-header-font{
    font-size: 3rem;
}

.colleges-header-row{
    max-height: 4rem;
    z-index: 9;
    /* background: linear-gradient(to right, rgb(1, 135, 1), rgb(0, 64, 25)); */
}

.negative-margin-top-half-rem{
    margin-top: -.5rem !important;
}

.school-name-bullets-text{
    font-size: 24px;
}

.bg-color-light-gray{
    background-color: rgb(233, 233, 233);
}

.college-list-draggable{
    cursor: move !important;
}

.no-bullets{
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
}

.add-even-more-subtle-shadow{
    -webkit-box-shadow: 0px 0px 27px 0px rgba(0,0,0,0.09); 
    box-shadow: 0px 0px 27px 0px rgba(0,0,0,0.09);
}

.hide-except-hover{
    display: none;
}
.show-on-hover:hover + .hide-except-hover{
    display: block;
}