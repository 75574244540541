.modal-width {

    min-width: 70% !important;
    
    
  }

  .modal-backdrop {
    width: 100% !important;
    height: 100% !important;
  }