.smaller-artful-row-after-header{
    background: linear-gradient(to right, rgb(1, 135, 1), rgb(0, 64, 25));
    height: 15rem;
    overflow: hidden;
    position: relative;
}

.vh100-container{
    height: 100vh;
}

.fill-100-percent{
    height: 70%;
}

.applications-intro{
    font-size: 5rem;
}