.welcome-header-row{
  
}

.welcome-header-row {
    color: rgb(1, 102, 1);
    background-color: rgba(1, 102, 1, 0.02);
}

.welcome-message{
    padding-top: 2em;
    padding-left: 2em;
}



.generic-welcome-message{
    margin-left: 0;
}

.main-welcome-container{
    background-color: rgba(0, 0, 0, 0.021);
    
    min-height: 100vh;
    margin: 0;
}

.header-divider {
    color: rgba(0, 0, 0, 0.71);
    margin-top: 0;
    margin-bottom: 0;
  }

  .tests-bootstrap-card{


    -webkit-box-shadow: 0px 0px 9px -2px rgba(0,0,0,0.42); 
box-shadow: 0px 0px 9px -2px rgba(0,0,0,0.42);
  }

  .official-test-button-group{
    
  }

  .main-welcome-screen{
    overflow: scroll;
    flex-grow: 1;
  }

  .scrollable-page{
    /* height: 10000px; */
  }

  .active-practice-module-welcome-screen:hover{
    cursor: pointer;
  }

  .practice-test-card{
    /* max-width: fit-content; */
    background-color: rgb(1, 102, 1) !important;
    -webkit-box-shadow: 0px 0px 9px -2px rgba(0,0,0,0.42); 
box-shadow: 0px 0px 9px -2px rgba(0,0,0,0.42);
  }

  .card-heading-white{
    color:white;
  }

  .text-primary-color{
    color: rgb(1, 102, 1);
  }

.welcome-hr{
    height: 0px;
    border: none;
    border-top: 4px solid rgb(1, 102, 1);
}

.welcome-message-card{
  background-color: rgb(1, 102, 1) !important;
  color: white;
  width: fit-content;
}

.cheap-spacer-row{
  min-height: 5rem;
}