.btn-custom-small {
  padding: 0.1rem 0.2rem;
  font-size: 0.75rem;
  line-height: 1.2;
  border-radius: 0.2rem;
}

.btn-custom-large {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
