.stats-panel {
  position: fixed;
  right: 0;
  width: 100%;
  height: 27rem;
  max-height: 27rem;
  background: white;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
  padding: 0px;
  transform: translateX(${props => props.show ? "0": "100%"});
  transition: transform 0.3s ease-in-out;
  z-index: 900;
  overflow: hidden;
}

.close-icon {
  border: white 2px solid;
  border-radius: 5px;
  cursor: pointer;
}

.stats-panel-header {
  background: #290078;
  color: white;
}

.stats-panel-body {
  overflow-y: auto;
  height: 100%;
  padding-bottom: 5rem;
}

.stats-panel-header.green-stats-panel-header {
  background: linear-gradient(to right, #0d5502, #052301);
}

.stats-panel-header.danger-stats-panel-header {
  background: linear-gradient(to right, #b70004, #5b0002);
}
