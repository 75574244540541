.annotation-color {
    width: 30px;
    height: 30px;
    border: 1px solid black;
    border-radius: 25px;
  }
  
  
  .annotation-modal-main{
    min-width: 100%;
  }

.annotate-modal-footer{
  background-color: #0C5602 !important;
}

.purple-button:hover{
  background-color: #4500ac !important;
}

.annotation-underline-style {
  display: inline-block;
  border-radius: 5px;
  border: 2px solid black;
  background-color: #ffffff;
  position: relative;
}

.purple-dashed-underline{
  border-bottom: 2px dashed #3a03b0;
  padding-bottom: 5px;
  padding-top: 5px;
}