.single-problem-type-header {
}

.main-problem-type-table-row {
  min-height: 70vh;
  max-height: 70vh;
  overflow: scroll;
}

.problem-type-footer-row {
  background-color: white;
  min-height: 8vh;
  max-height: 8vh;
  position: absolute;
  bottom: 0;
  width: 103.6%;
}

.dropup-number-spacer {
  min-width: 2.6rem;
}

#problem-subtype-dropup.dropdown-menu {
  background-color: red;
  min-width: 500px;
}

/* Dropup Button for subtype*/
.custom-subtype-dropbtn {
  background-color: black;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  border-radius: 15px;
}

/* Dropup Button for subtype*/
.student-dropup-dropbtn {
  background-color: black;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  border-radius: 15px;
}

/* The container <div> - needed to position the dropup content */
.custom-subtype-dropup {
  position: relative;
  display: inline-block;
}
/* The container <div> - needed to position the dropup content */
.student-dropup {
  position: relative;
  display: inline-block;
}

/* Dropup content (Hidden by Default) */
.custom-subtype-dropup-content {
  display: none;
  position: absolute;
  bottom: 4rem;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 0, 0, 1) 3.5rem,
    rgba(255, 255, 255, 1) 4rem,
    rgba(255, 255, 255, 1) 100%
  );
  border-radius: 25px;
  min-width: 30rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 25;
}
/* Dropup content (Hidden by Default) */
.student-dropup-content {
  display: none;
  position: absolute;
  bottom: 4rem;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 0, 0, 1) 3.5rem,
    rgba(255, 255, 255, 1) 4rem,
    rgba(255, 255, 255, 1) 100%
  );
  border-radius: 25px;
  min-width: 30rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 25;

  transform: translateX(-17rem) !important;
}

/* Show the dropup menu on hover */
.custom-subtype-dropup.dropup-active
  .custom-subtype-dropup-content.dropup-active {
  display: block;
}
/* Show the dropup menu on hover */
.student-dropup.dropup-active .student-dropup-content.dropup-active {
  display: block;
}

/* Change the background color of the dropup button when the dropup content is shown */
.custom-subtype-dropup:hover .custom-subtype-dropbtn {
  background-color: #3b3b3b;
}

/* Change the background color of the dropup button when the dropup content is shown */
.student-dropup:hover .student-dropbtn {
  background-color: #3b3b3b;
}

.stretch-x-dimension {
  transform: scaleX(1.01);
}

.problem-type-select-button {
  min-width: 5rem;
}

.single-type-table {
  max-width: 95vw !important;
}

.main-problem-type-row {
  max-height: 2rem !important;
}

.finish-assigning-button {
  max-height: 3rem;
  background-color: black !important;
}

.subheading-small {
  font-size: x-small;
}

.select-deselect-button {
  min-width: 5.5rem;
}

.problem-type-footer-buttons .gray-button {
  background-color: gray;
  border: 1px solid black;
}

.problem-type-footer-buttons .gray-button:hover {
  background-color: rgb(0, 50, 0);
}

.problem-preview-modal {
  min-width: 100vw;
  scale: 0.8;
  max-height: 90vh !important;
  overflow: hidden;
}

.problem-preview-modal .modal-dialog {
  max-height: 85vh;
}

.problem-preview-modal .modal-body {
  max-height: calc(85vh - 8rem);
  overflow: scroll;
}

.problem-preview-modal-header {
}

.modal-navigation-button {
  min-width: 5rem;
}

.modal-assign-checkbox {
  scale: 1.5;
}

.modal-thin-line {
  margin-left: -1rem;
  margin-right: -1rem;
}

#modal-footer-problem-preview {
  background-color: black;
}

.problem-preview-footer-row {
  min-width: 100%;
}
