#dropdown-more {
  color: black;
}

#fontAwesomeIcon {
  color: black !important;
}

.referenceSheetContainer {
  position: absolute;
  top: 0;
  left: 0;
}
