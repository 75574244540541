.sticky-note {
  position: relative;
  border: 1px solid black;
  border-radius: 8px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  font-family: "Comic Sans MS", "Comic Sans", cursive;
  padding: 8px;
  overflow-x: hidden;
  cursor: pointer;
}

.sticky-note.active {
  border: 2px solid black;
}

.sticky-note-header {
  background-color: #fffacf;
}

.sticky-note-header.yellow.active {
  background-color: #fdd50b;
}

.sticky-note-header.pink {
  background-color: #fdd3f3;
}

.sticky-note-header.pink.active {
  background-color: #f380d6;
}

.sticky-note-header.blue {
  background-color: #dbefff;
}

.sticky-note-header.blue.active {
  background-color: #81c0f9;
}

.note-text {
  overflow: hidden; /* prevent text from overflowing */
  white-space: nowrap; /* keep the text in a single line */
  text-overflow: ellipsis; /* add ellipsis if text overflows */
}

.note-delete-button-container {
  float: right;
  color: #333; /* Dark icon color for contrast */
  background: none; /* remove any background */
  border-radius: 50%;
  background-color: white;
  padding: 5px 7px 5px 7px;
}

.annotation-trash-icon {
  color: #6e6d6d;
}

.annotation-trash-icon:hover {
  color: #3d3d3d;
  cursor: pointer;
}

.delete-confirmation-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmation-content {
  color: white;
  padding: 0px;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.auto-resize-textarea:focus {
  outline: none;
  box-shadow: none;
}

.auto-resize-textarea {
  resize: none;
  border: none;
  min-height: 4rem;
}

.underline-none {
  text-decoration: none;
}

.annotation-underline-solid {
  text-decoration: underline solid;
  text-underline-offset: 0.3em;
}

.annotation-underline-dashed {
  text-decoration: underline dashed;
  text-underline-offset: 0.3em;
}

.annotation-underline-dotted {
  text-decoration: underline dotted;
  text-underline-offset: 0.3em;
}

.note-textarea-container {
  min-height: 4rem;
}

.annotation-note-reject-delete-button {
  border: 3px solid white;
}

.annotation-note-confirm-delete-button {
  border: none !important;
}
