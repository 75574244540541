.question-preview-font-size, .question-preview-font-size * {
    font-size: 1rem;
  }

  
  .pulsing-shadow-more {
    position: relative;
    animation: pulse-more 2s infinite;
}

@keyframes pulse-more {
    0% {
        box-shadow: 0 0 0 0 #0F6B05;
    }
    50% {
        box-shadow: 0 0 7px 4px #0F6B05;
    }
    100% {
        box-shadow: 0 0 0 0 #0F6B05;
    }
}

.review-button-image-container {
    position: relative;
    border-radius: 25px;
    min-height: 3.5rem;
    min-width: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.review-button-image-container img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 1s ease-in-out;
    max-width: 80%;
    max-height: 80%;
}

  