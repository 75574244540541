.main-score-font {
  font-size: 2.9rem;
  font-weight: 600;
}

.score-font {
  font-size: 1.5rem;
  font-weight: 600;
}

.white-border {
  border: 1px solid white;
  border-radius: 10px;
  max-width: fit-content;
  max-height: fit-content;
}

.section-heading-font {
  font-size: 1.3rem;
  font-weight: 600;
}

.test-name-title-font {
  font-size: 1.7rem;
  font-weight: 600;
}

.take-full-page-minus-header {
  min-height: calc(100vh - 11.5rem);
}

.section-data-container {
  border-radius: 15px;
  overflow: hidden;
}

.alert-sticky {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background-color: #eaeae8;
}

.page-container {
  height: 100vh;
  overflow-y: scroll;
  background-color: #eaeae8;
}

.purple-pulsing-shadow-more {
  position: relative;
  animation: purple-pulse-more 2s infinite;
}

@keyframes purple-pulse-more {
  0% {
    box-shadow: 0 0 0 0 #7328ff;
  }
  50% {
    box-shadow: 0 0 7px 4px #7328ff;
  }
  100% {
    box-shadow: 0 0 0 0 #7328ff;
  }
}

.red-pulsing-shadow-more {
  position: relative;
  animation: red-pulse-more 2s infinite;
}

@keyframes red-pulse-more {
  0% {
    box-shadow: 0 0 0 0 #b70004;
  }
  50% {
    box-shadow: 0 0 7px 4px #b70004;
  }
  100% {
    box-shadow: 0 0 0 0 #b70004;
  }
}

.green-pulsing-shadow-more {
  position: relative;
  animation: green-pulse-more 2s infinite;
}

@keyframes green-pulse-more {
  0% {
    box-shadow: 0 0 0 0 #0d5502;
  }
  50% {
    box-shadow: 0 0 7px 4px #0d5502;
  }
  100% {
    box-shadow: 0 0 0 0 #0d5502;
  }
}
