@font-face {
    font-family: "Grape Nuts", cursive;
    src: local("Grape Nuts"),
      url("./fonts/GrapeNuts-Regular.ttf") format("truetype");
    font-weight: normal;
    font-family: 'Amatic SC', cursive;
    src: local("Amatic SC"),
      url("./fonts/AmaticSC-Regular.ttf") format("truetype");
      font-family: 'Amatic SC', cursive;
    src: local("Luckiest Guy"),
      url("./fonts/LuckiestGuy-Regular.ttf") format("truetype");
 
  }
