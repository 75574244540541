.break-page-container ul {
    list-style: none;
  }
  
  .break-page-timer{
    font-size: 4rem;
  }

  .main-break-page-row{
    overflow: scroll;
  }