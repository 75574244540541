
@import url('https://fonts.googleapis.com/css2?family=Grape+Nuts&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&family=Source+Sans+Pro&display=swap');


body {
    /* max-height: 100vh; */
    overflow: scroll;
  }
  
  .sidebar-container{
    overflow: hidden;
    position: fixed;
  }



  .main-screen-container-false{
    
  }