.sign-in-start-header:hover{
    cursor: pointer;
}

.text-green{
    color: rgb(1, 102, 1) !important;
}

.overall-start-page-header{
    background-color: white;
    height: 4.9rem;

}

.welcome-menu-dropdown{
    color: white;
    min-height: 30rem;
    width: 100vw;
    border: none;
    background-color: black; 
    z-index: 10;
    position: relative;
    -webkit-box-shadow: 20px 800px 0px 800px rgba(0, 0, 0, 0.767); 
    box-shadow: 20px 800px 0px 800px rgba(0, 0, 0, 0.767);
}

.account-menu-dropdown{
    color: white;
    min-height: 26rem;
    width: 100vw;
    border: none;
    background-color: rgb(238, 238, 237);
    z-index: 10;
    position: relative;
    -webkit-box-shadow: 20px 800px 0px 800px rgb(238, 238, 237, 0.767); 
    box-shadow: 20px 800px 0px 800px rgba(238, 238, 237, 0.767);
}

.account-info-top-header:hover{
    cursor: pointer;
}

.company-logo {
    background-color: black;
    height: 100%;
}

.account-info-top-header{
    /* background-color: rgb(238, 238, 237); */
    max-width: fit-content;
    min-height: 92%;
    margin-bottom: 1rem !important;
    /* box-shadow: 0 8px 6px -6px black; */
}

.account-info-dropdown-card {
    min-height: 95%;
    color: black;
    border-radius: 5% !important;
}

.blue-line-row{
    background: linear-gradient(to right, rgb(0, 255, 234), rgb(0, 170, 255));
    height: 5px;
    z-index: 9;
    width: 100%;
    border-radius: 20px;
    margin: auto;
}

.green-line-row{
    background: linear-gradient(to right, rgb(2, 176, 2), #004019);
    height: 5px;
    z-index: 9;
    width: 100%;
    border-radius: 20px;
    margin: auto;
}

.green-line-row-thin{
    background: linear-gradient(to right, rgb(2, 176, 2), rgb(0, 64, 25));
    height: 3px;
    z-index: 9;
    width: 100%;
    border-radius: 20px;
    margin: auto;
}

.red-line-row-thin{
    background: linear-gradient(to right, rgb(176, 2, 2), rgb(64, 0, 0));
    height: 3px;
    z-index: 9;
    width: 100%;
    border-radius: 20px;
    margin: auto;
}

.yellow-line-row-thin{
    background: linear-gradient(to right, rgb(255, 242, 0), rgb(175, 166, 0));
    height: 3px;
    z-index: 9;
    width: 100%;
    border-radius: 20px;
    margin: auto;
}

/* Specify the background with inline styling */
.no-color-line-row-thin{
    height: 3px;
    z-index: 9;
    width: 100%;
    border-radius: 20px;
    margin: auto;
}

.purple-line-row{
    background: linear-gradient(to right, #4300a7, #130030);
    height: 5px;
    z-index: 9;
    width: 100%;
    border-radius: 20px;
    margin: auto;
}

.white-line-row{
    background: white;
    height: 5px;
    z-index: 9;
    width: 100%;
    border-radius: 20px;
    margin: auto;
}

.black-line-row{
    background-color: black;
    height: 5px;
    z-index: 9;
    width: 100%;
    border-radius: 20px;
    margin: auto;
}

.purple-line-row-thin{
    background: linear-gradient(to right, #4300A7, #130030);
    height: 2px;
    z-index: 9;
    width: 100%;
    border-radius: 20px;
    margin: auto;
}
.black-line-row-thin{
    background: black;
    height: 2px;
    z-index: 9;
    width: 100%;
    border-radius: 20px;
    margin: auto;
}

.white-line-row-thin{
    background: white;
    height: 2px;
    z-index: 9;
    width: 100%;
    border-radius: 20px;
    margin: auto;
}
.pink-line-row{
    background: linear-gradient(to right, rgb(230, 37, 255), rgb(123, 0, 112));
    height: 5px;
    z-index: 9;
    width: 100%;
    border-radius: 20px;
    margin: auto;
}

.orange-line-row{
    background: linear-gradient(to right, rgb(255, 121, 37), rgb(123, 59, 0));
    height: 5px;
    z-index: 9;
    width: 100%;
    border-radius: 20px;
    margin: auto;
}

.gray-line-row{
    background: linear-gradient(to right, rgb(171, 171, 171), rgb(53, 53, 53));
    height: 5px;
    z-index: 9;
    width: 100%;
    border-radius: 20px;
    margin: auto;
}

.account-panel-sign-out{
    max-width: fit-content !important;
    background-color: rgb(1, 102, 1) !important;
}

.cheap-translate-x{
    transform: translateX(50px);
}

.blue-line-row-thin{
    background: linear-gradient(to right, rgb(0, 255, 234), rgb(0, 170, 255));
    height: 2px;
    z-index: 9;
    width: 100%;
    border-radius: 20px;
    margin: auto;
}

body.dropdown-open {
    overflow: auto;
  }

  .header-color-button-spacer{
    padding-left: 10px;
  }

  .disabled-link{
    color: gray;
  }

  .resolve-header-font{
    font-family: 'Amatic SC' ;
    padding-left: 3px;
    padding-right: 3px;
    /* border: 1px solid rgb(0, 79, 0); */
    /* background-color: rgb(0, 79, 0); */
    height: fit-content;
    color: white;
    font-weight: 900;
    font-size: 4.2rem;
    line-height: 4.2rem;
   
  }

  .resolve-subtitle{
    font-family: 'Amatic SC' ;
    padding-left: 3px;
    padding-right: 3px;
    color: white;
    font-weight: 900;
    font-size: 1.5rem;
  }

  .resolve-header-font-container{
     
  }


.font-visible {
    visibility: visible;
}
