.annotation-popup {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
  position: relative;
  top: -50px; /* Adjust as needed */
  left: 50%; /* Center the popup */
  transform: translateX(-50%);
  z-index: 1000;
  width: fit-content;
}

.popup-button {
  margin: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid black;
  border-radius: 50%;
  cursor: pointer;
  line-height: 0;
}

.dropdown-button {
  width: 50px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-divider {
  width: 1px;
  height: 24px;
  background-color: #e0e0e0;
  margin: 0 10px;
}

.annotation-dropdown-menu div {
  padding: 10px;
  cursor: pointer;
}

.annotation-dropdown-menu div:hover {
  background-color: #f0f0f0;
}

.multi-underline {
  position: relative;
  display: inline-block;
  border-bottom: 1px solid;
}

.multi-underline::before,
.multi-underline::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  pointer-events: none;
}

.multi-underline::before {
  border-bottom: 1px dashed;
  margin-top: 0.4em;
}

.multi-underline::after {
  border-bottom: 1px dotted;
  margin-top: 0.6em;
}

.annotation-solid-underline {
  text-decoration: underline solid;
  text-underline-offset: 0.2em;
}
.annotation-dashed-underline {
  text-decoration: underline dashed;
  text-underline-offset: 0.2em;
}
.annotation-dotted-underline {
  text-decoration: underline dotted;
  text-underline-offset: 0.2em;
}

.annotation-dropdown-container {
  position: relative;
}

.annotation-dropdown-menu {
  position: absolute;
  top: 120%;
  left: 0;
  line-height: 0.75rem;
  width: 80px;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-top: 5px;
  z-index: 1001;
  transform: translateX(0); /* No need to translate horizontally */
}
