.graphing-calculator {
    width: 30rem;
    height: 40rem;
    display: flex;
    flex-direction: column;
  }
  
  .input-area {
    flex: 1;
  }
  
  .graph-area {
    flex: 3;
  }
  
  .calculator-keyboard {
    flex: 2;
  }
  