.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px; /* Adjust height as per your header */

  z-index: 1000; /* Ensures it stays on top */
  border-bottom: 2px dashed #000000; /* Optional: adds a border at the bottom */
}

.header-green {
  background-color: #c9dac5;
}

.header-red {
  background-color: #f5cac9;
}

.reviewModeBox {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: -0.3rem;
  text-align: center;
}

.reviewModeBoxIncorrect {
  border: 2px solid #b70004;
  color: #b70004;
}

.reviewModeBoxCorrect {
  border: 2px solid #0d5502;
  color: #0d5502;
}
