.artful-row-after-header-extra-small{
    width: 100vw;
    background: linear-gradient(to right, rgb(1, 136, 1), rgb(0, 55, 21));
    overflow: hidden;
    position: relative;
}

.test-history-card-overall{
    font-size: 1.8rem;
}
.test-history-card-overall-score{
    margin-top: -.5rem;
}

.test-history-card-subscores{
    font-size: 1.5rem;
    margin-top: -.25rem;
}
.line-under-subscore{
    margin-right: auto;
    margin-left: 0;
    margin-top: -.25rem;
    margin-bottom: 0;
    max-width: 4.5rem;
}

.line-card-separator{
    max-width: 90%;
}

.test-history-button{
    max-height: 2.5rem !important;
    min-width: 6.5rem !important;
    z-index: 1;
}

.hover-enlarge-more:hover{
    cursor: pointer !important;
    scale: 1.03;
    transition: transform .5s ease;
}

.overall-header-card{
    min-height: 10rem;
}

.margin-right-one-pixel{
    margin-right: -5px;
}

.adjust-reading-sub{
    margin-right: 9px;
}

.test-history-row-positioned-above-artful {
    position: absolute;
    color: white;
}

.visually-hidden { 
    position: absolute !important;
    height: 1px; 
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 - a 0 height clip, so the size is less than the size of a scrollbar */
    clip: rect(1px, 1px, 1px, 1px); /*maybe deprecated but we need to support legacy browsers */
    clip-path: inset(50%); /*modern browsers, clip-path works inwards from each corner*/
    white-space: nowrap; /* added line to stop words getting smushed together (as they go onto seperate lines and some screen readers do not understand line feeds as a space */
  }
  