.answer-key-section-heading{
    /* border: 1px solid rgba(165, 163, 163, 0.119); */
    border-radius: 25px;
    opacity: .9;
}

.answer-key-table-header{
   
}

.text-gray{
    color: #6e6e6e !important;
}

.act-answers-zoom{
    scale: .8;
    margin-top: -7rem !important;
}

.important-bgcolor-green{
    background-color: rgba(1, 73, 1, 0.162) !important;
}
.important-bgcolor-yellow{
    background-color: rgba(255, 255, 0, 0.076) !important;
}
.important-bgcolor-purple{
    background-color: rgba(67, 0, 83, 0.075) !important;
}