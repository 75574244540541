.navigationBoxWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.locationIcon {
  margin-bottom: -0.4rem; /* Adjust the spacing between the icon and the button */
  font-size: 0.8rem; /* Set the size of the icon */
  color: black; /* Adjust the color as needed */
}

.question-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
  border-radius: 4px;
  padding: 10px;
  width: 25px;
  height: 25px;
  margin: 3px;
}

.correctBox {
  background-color: green;
  color: white;
}

.wrongBox {
  background-color: red;
  color: white;
}

.uncompletedBox {
  background-color: white;
  border: 2px dashed black;
  color: black;
}

.bookmarkIcon {
  margin-bottom: -0.9rem; /* Adjust the spacing between the icon and the button */
  margin-left: 1.3rem;
  font-size: 0.8rem;
  color: #094b00; /* Adjust the color as needed */
  z-index: 10;
}
