.registered-symbol-lg{
    vertical-align: 3em;
  font-size: smaller; /* Optionally, you can also make the symbol smaller if needed */

}

.registered-symbol-xl{
    vertical-align: 3.5em;
  font-size: smaller; /* Optionally, you can also make the symbol smaller if needed */

}

.registered-symbol-md{
    vertical-align: 2.5em;
  font-size: smaller; /* Optionally, you can also make the symbol smaller if needed */

}