.level-up-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000; /* Ensure it's above other content */
}

.level-up-modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
}

/* You might want to add some styling for the text and other elements inside the modal */
.level-up-modal h1 {
    margin: 0;
    color: #333;
    font-size: 2em;
}
