.geogebra{
    border: 1px solid #e4e4e4;
    border-radius: 4px;
}

.overall-calculator-container{
    
}

.calculator-header{
    background-color: black;
    height: 2rem;
    color: white;
}

.modal-height {
    min-height: max-content !important;
  }

  .modal-width {
    min-width: max-content !important;
  }

  