.practice-sidebar-style {
  
    height: 100%;
    padding: 20px;
    transition: width 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    position: relative;
  }
  
  .practice-sidebar-collapsed {
    width: 60px;
  }
  
  .practice-sidebar-expanded {
    width: 280px;
  }
  
  .practice-menu-list-style {
    list-style-type: none;
    padding: 0;
    margin: 0;

  }
  
  .practice-menu-item-style {
    margin-bottom: 10px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: width 0.3s ease, border-radius 0.3s ease;
  }
  
  .practice-menu-item-expanded {
    border-radius: 25px;
    width: 200px;
  }
  
  .practice-arrow-button {
    position: absolute;
    left: 110%;  
    top: calc(50% - 10px);  
    transform: translateY(-50%);
    padding-right: 3px;
    width: 20px;
    height: 20px;
    background-color: #0D5502;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
  }

  .practice-arrow-button-expanded {
    position: absolute;
    left: 77%;  
    top: calc(50% - 10px);  
    transform: translateY(-50%);
    padding-left: 5px;
    width: 20px;
    height: 20px;
    background-color: #0D5502;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
  }
  
  
  .practice-arrow {
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
  }
  
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  
  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  