.compressed-vocab-card{
    min-height: 10rem;
}

.vocab-word-definition-font{
    font-size: 1.5rem;
    font-weight: 400;
}

.vocab-ribbon {
    position: absolute;
    top: -30px; 
    left: -10px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: center;
    transform: rotate(270deg);
    z-index: 100;
  }
  
  .vocab-ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    display: block;
    background: #4300a7;
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px; 
    left: -21px;
    width: 100px;
  }
  