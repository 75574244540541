/* Basic styles for the mark */
.mark-tooltip {
  padding: 0;
  margin: 0;
  cursor: pointer;
}

/* Color classes */
.mark-yellow {
  background-color: #fffacf;
}

.mark-blue {
  background-color: #e2f3ff;
}

.mark-pink {
  background-color: #ffddf6;
}

/* Active state color adjustments */
.mark-yellow.active {
  background-color: #fccd10; /* Darker shade of yellow for active state */
}

.mark-yellow:hover {
  background-color: #fccd10; /* Hover state color adjustment */
}

.mark-blue.active {
  background-color: #70b1f7; /* Darker shade of blue for active state */
}

.mark-blue:hover {
  background-color: #70b1f7; /* Hover state color adjustment */
}

.mark-pink.active {
  background-color: #ed64cd; /* Darker shade of pink for active state */
}

.mark-pink:hover {
  background-color: #ed64cd; /* Hover state color adjustment */
}

/* Underline styles */
.underline-none {
  text-decoration: none;
}

.underline-solid {
  text-decoration: underline solid;
  text-underline-offset: 0.3em;
}

.underline-dashed {
  text-decoration: underline dashed;
  text-underline-offset: 0.3em;
}

.underline-dotted {
  text-decoration: underline dotted;
  text-underline-offset: 0.3em;
}
