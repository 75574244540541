.artful-row-after-header-very-small{
    background: linear-gradient(to right, rgb(1, 135, 1), rgb(0, 64, 25));
    height: 14rem;
    overflow: hidden;
    position: relative;
}

.rounded-corners{
    border-radius: 15px;
}

.upper-case{
    text-transform: uppercase;
}


.college-checklist{
    list-style-type:none;
}
.what-colleges-want-header{
    background-color:  #230070;
    margin: 0;
}