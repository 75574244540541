.bg-color-teal-gradiant{
    background: linear-gradient(to right, rgb(1, 113, 136), rgb(0, 48, 58));
}

.bg-color-fuscia-gradiant{
    background: linear-gradient(to right, rgb(136, 1, 113), rgb(58, 0, 48));

}
    .bg-color-green-gradiant{
        background: linear-gradient(to right, rgb(1, 126, 1), rgb(0, 43, 17));
    }
    
    .bg-color-purple-gradiant{
        background: linear-gradient(to right, rgb(75, 23, 164), rgb(31, 0, 64));
    }
