/* LearnSatReading.css */

.learnSatReading {
    font-family: Arial, sans-serif;
    color: #333;
    border: 1px solid #333;
    border-radius: 10px;
    background-color: #f9f9f9;
    margin: 1rem;
    padding: 1rem;
  }
  
  .header-line {
    width: 100%;
    height: 2px;
    background-color: #290078;
    margin-bottom: 1rem;
  }
  
  .text-center {
    text-align: center;
  }
  
  .content-section {
    margin: 2rem 0;
  }
  
  .content-section h3 {
    color: #290078;
    margin-bottom: 1rem;
  }
  
  .problem-type-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .problem-type-box {
    padding: 0.5rem;
    margin: 0.5rem 0;
    background-color: #fff;
    border: 1px solid #290078;
    border-radius: 5px;
    color: #290078;
    cursor: pointer;
    flex: 0 0 calc(50% - 1rem);
  }
  
  .problem-type-box:hover {
    background-color: #290078;
    color: #fff;
  }
  

.intro-section {
  margin: 1rem;
    padding: 1rem;
    border: 1px dashed #290078;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .intro-section p {
    color: #333;
    line-height: 1.5;
  }

  .purple-outline-button {
    background-color: #fff !important;
    border: 1px solid #290078 !important;
    color: #290078 !important;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-align: center;

  }

  .purple-outline-button:hover {
    background-color: #290078 !important;
    color: #fff !important;
  }

  .purple-button-div {
    text-align: center;
    background-color: #290078 !important;
    color: #fff !important;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

  }

  .purple-button-div:hover {
    background-color: #39019f !important;
    color: #ffffff !important;
  }