.search-box-container{
    z-index: 9;
}

.item-autocomplete-container{
    max-height: 15rem !important;
    background-color: red;
    max-width:fit-content;
    min-width: 12rem;
    overflow: scroll;
    margin-top: 1rem !important;
    background-color: rgb(255, 255, 255);
    border: 1px solid black;
    border-radius: 20px;
    margin-left: 0rem;
}

#collegeInput{
    min-width: fit-content;
}