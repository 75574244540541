.main-content-above-footer {
  padding-bottom: 5rem; /* This must stay linked with footer height below */
  overflow-y: auto;
  height: 100vh;
}

.footer {
  position: fixed;
  bottom: 0;
  height: 5rem; /* This must still linked with padding-bottom above. */
  z-index: 100;
  width: 100vw;
}

.dashed-border-top {
  /* This adds the dashed border with custom dash length and gap */
  background-image: linear-gradient(
    to right,
    black 70%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: top;
  background-size: 30px 3px;
  background-repeat: repeat-x;
}

.footer-navigation-buttons {
  min-width: 5rem;
}

/* adjust .footer-navigation-buttons for xs screens */
@media (max-width: 575.98px) {
  .footer-navigation-buttons {
    min-width: 2.6rem;
  }
}

.question-navigation-button {
  background-color: white;
  color: black;
  font-weight: bolder;
  max-height: 2.5rem;
  border: 1px solid black !important;
}

/*this gives the fancy line look*/

hr.fancy-line {
  border: 0;
  height: 0.2em;
  position: relative;
  margin: 0; /* Keep other elements away from pseudo elements*/
}
hr.fancy-line:after {
  top: -0.5em;
  height: 1em;
}
hr.fancy-line:before {
  content: "";
  height: 0.5em;
  top: -0.5em;
  z-index: 999;
}

hr.fancy-line:before,
hr.fancy-line:after {
  content: "";
  position: absolute;
  width: 100%;
}

hr.fancy-line,
hr.fancy-line:after {
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0) 75%
  );
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    75%,
    color-stop(50%, rgba(0, 0, 0, 0.1)),
    color-stop(75%, rgba(0, 0, 0, 0))
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0) 75%
  );
  background: -o-radial-gradient(
    center,
    ellipse cover,
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0) 75%
  );
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0) 75%
  );
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0) 75%
  );
}

body,
hr.fancy-line:before {
  background: #f4f4f4;
}

.overall-popup {
  min-height: 350px;
  max-height: 400px;
  min-width: 580px;
  max-width: 5800px;
  border: solid 1px rgba(0, 0, 0, 0.066);
  background-color: white;
  border-radius: 25px;
  position: absolute;
  bottom: 10rem;
  left: 50%;
  z-index: 1000 !important;
  transform: translate(-50%, 6rem);
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.39);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.39);
}

@media (max-width: 575.98px) {
  .overall-popup {
    min-height: calc(100vh - 10rem) !important;
    max-height: calc(100vh - 10rem) !important;
    min-width: 100vw; /* Set minimum width to 94% of the viewport width */
    max-width: 100vw; /* Set maximum width to 94% of the viewport width */
    top: calc((100vh - 11rem)) / 2; /* Center vertically */
    left: 2rem; /* Center horizontally */
    transform: translate(
      calc(-1 * (49vw)),
      calc((13.5vh))
    ); /* Offset to center */
    position: fixed; /* Fixed position to stay in the center of the viewport */
    z-index: 1051;
    border-radius: 0px;
    overflow: scroll;
  }
}

.dotted-box-question-key {
  border: dashed 2px black;
  width: 1.5em;
  height: 1.5em;
}

.test-module-dropup {
}

.test-module-dropup > .dropdown-menu {
  border: none !important;
  outline: none;
  background-color: transparent !important;
  background: #000000;
}

.dotted-box-unanswered-question {
  border: dashed 2px black;
  width: 2.3em;
  height: 2em;
  color: rgb(1, 102, 1);
}

.solid-answered-question {
  border: solid 1px black;
  background-color: rgb(1, 102, 1);
  color: white;
  width: 2.3em;
  height: 2em;
}

.added-bookmark-to-problem {
  background-color: white;
  padding: 2px 2px 2px 2px;
  position: absolute;
  margin-left: 35px;
  margin-top: -8px;

  -webkit-box-shadow: 0px 1px 5px 2px #000000;
  box-shadow: 0px 1px 5px 2px #000000;
}

.added-location-to-problem {
  background-color: white;
  padding: 2px 2px 2px 2px;
  position: absolute;
  margin-left: 0px;
  margin-top: -18px;
  color: black;

  /* -webkit-box-shadow: 0px 1px 5px 2px #000000; 
    box-shadow: 0px 1px 5px 2px #000000; */
}

.footer-bg-color-red {
  background-color: #f5cac9 !important;
}

.footer-bg-color-green {
  background-color: #c9dac5 !important;
}

.footer-bg-color-purple {
  background-color: #ebeaff !important;
}
