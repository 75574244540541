/* calculator-keyboard.css */

.calculator-keyboard {
    display: flex;
    background-color: lightgray;
  }
  
  .group {
    flex: 1;
  }
  
  .group-1 .button,
  .group-2 .button {
    width: 25%;
    height: 3rem;
    font-size: 1.5rem;
  }
  
  .button {
    background-color: white;
    color: black;
  }
  
  .digit {
    background-color: darkgray;
    color: white;
  }
  
  .return {
    background-color: blue;
    color: white;
  }
  