.overall-sidebar{
    background-color: black;
    width: 180px;
    height: 100vh;
    padding-left: 5px;
}

.side-label{
    text-align: left;
    color: white;
    padding-left: 5px;
    padding-top: 8px;
}

.side-label-container{
    padding-left: 0;
}

.logo-img{
    align-items: flex-start;
    height: 4.5em;
    width: 4.5em;
    padding-left: 5px;
    margin-left: 10px;
    
}

.font-awesome-icon{
    align-items: flex-start;
    text-align: right;
    margin-right: 0;
    padding-right: 0;

}

.icon-container{
    align-items: flex-start;
    justify-content: start;
    text-align: right;
    padding-right: 0;
    min-width: 60px
}

.menu-item-container{
    align-items: center;
    justify-content: center;
    margin-right: 0px !important;
}

.menu-item-container:hover{
    background-color: rgb(1, 102, 1);
    cursor: pointer;
}

.main-logo-container:hover{
    background-color: rgb(1, 102, 1);
    cursor: pointer;
}

.main-logo-container{
    margin-right: 0px !important;
}

.first-item-in-sidebar{
    padding-top: 40% !important;
}

.help-button {
    position : absolute;
    bottom   : 0;
}