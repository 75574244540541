.practice-module-header {
  background-color: white;
  margin: auto;
  width: 100%;
  height: 5.3rem;
  z-index: 101;

  /* This adds the dashed border with custom dash length and gap */
  background-image: linear-gradient(
    to right,
    black 70%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 30px 3px;
  background-repeat: repeat-x;

  /* This adds the shadow */
  /* -webkit-box-shadow: 0 4px 6px -6px #222;
  -moz-box-shadow: 0 4px 6px -6px #222;
  box-shadow: 0 4px 6px -6px #222; */
}

.drawbox {
  border: 1px solid black;
}

.font-awesome-black {
  color: black;
}

.font-awesome-black:hover {
  color: rgb(1, 102, 1);
}

.module-header-right-icon {
  height: 100%;
  min-width: 5.5rem;
  cursor: pointer;
}

/* adjust module-header-right-icon for xs screens  */
@media (max-width: 575.98px) {
  .module-header-right-icon {
    min-width: 2.5rem;
  }
}

.module-header-right-icon:hover {
  color: rgb(1, 102, 1);
}

.module-header-right-icon:hover .font-awesome-black {
  color: rgb(1, 102, 1);
}

.dropdown-toggle:after {
  border: 0;
}

.more-menu-item {
  font-size: large;
}

.more-dropdown-menu {
  width: 240px;
  background-color: white;
}

.show.dropdown:hover .font-awesome-black {
  color: black;
}

.dropdown a:hover {
  color: black;
  background-color: rgba(1, 102, 1, 0.134);
}

.navbar-nav .open .dropdown-menu {
  background-color: #fff;
}

.directions-dropdown {
  min-width: 70vw;
  margin-top: 0.75rem;
  padding: 1rem;
}
@media (max-width: 575.98px) {
  .directions-dropdown {
    min-width: 100vw;
    margin-top: 0px;
    padding: 0px;
  }
}

.instructions-scroll-container {
  max-height: 70vh;
  min-width: 70vw;
  min-height: 70vh;
  overflow: scroll;
}
/* 
adjust instructions-scroll-container for xs screens */
@media (max-width: 575.98px) {
  .instructions-scroll-container {
    min-width: 100vw;
    max-width: 100vw;
    min-height: calc(100vh - 10rem);
  }
}

#instructions-dropdown {
  margin-top: 0;
  background-color: transparent;
  color: black;
  border: none;
}

.hide-button {
  margin-left: -0.8rem;
}

.DISABLEdropdown-menu.show {
  /* needed styles for the overlay */
  z-index: 10; /* keep on top of other elements on the page */
  outline: 9999px solid rgba(0, 0, 0, 0.5);
}

/* This Grays out the background for the directions */
.hide-button .dropdown-menu.show {
  -webkit-box-shadow: 20px 800px 0px 800px #454444af;
  box-shadow: 20px 800px 0px 800px #454444af;
}

.bg-color-transparent {
  background-color: transparent !important;
}

/* Media query for xs screens */
@media (max-width: 575.98px) {
  .instructions-scroll-container {
    min-width: 100vw;
  }
}
