.box-around-pane-expand {
  border: 1px rgba(115, 115, 115, 0.356) solid;
  border-radius: 30px;
  max-width: fit-content !important;
  box-shadow: 0 0 0 4px #9d9d9d13;
}

@media (min-width: 768px) {
  .add-center-vertical-line {
    border-left: 5px solid rgb(118, 118, 118);
    height: 100vh;
  }
}

.pane-content-container {
  position: relative;
}

.expand-icon-container {
  position: absolute;
  top: 50;
  right: 0;
  z-index: 50;
}

.grid-in-instructions {
  font-size: large;
}

ul.grid-in-instructions > li {
  margin-left: 2rem;
  margin-top: 0.25rem;
}

/* .main-grid-in-instructions-container {
  max-height: 85vh;
} */

.grid-in-examples-table {
  max-width: fit-content !important;
}

.math-problem-no-grid-in {
  max-width: 1000px !important;
}

.box-around-pane-expand:hover {
  color: green;
  cursor: pointer;
}

.overflow-scroll {
  /* overflow-y: scroll; */
  /* max-height: 80vh; */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.overflow-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px; /* Adjust the width of the scrollbar */
  height: 8px;
}

.overflow-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the color of the scrollbar thumb */
  border-radius: 4px; /* Optional: Adjust the border-radius of the thumb */
  border: 1px solid #ffffff; /* Optional: Add border to the thumb */
}

.main-question-font-size {
  font-size: 1.2rem;
}

.box-around-pane-expand:hover {
  color: rgb(2, 80, 2);
  cursor: pointer;
  transform: scale(1.1);
}
